<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea mNone">
			<div class="inner">
				<h2>건물 등록</h2>
			</div>
		</div>
		<!--// titArea -->
		<BuildingFrom :value="data" @save="onSave" labelCancel="취소" labelSave="건물등록"></BuildingFrom>
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner, hideMenu, getCoordinate } from '@/utils/cmm';
import buildingFrom from './buildingForm';
import file from '@/api/file';
import building from '@/api/building';
import tenant from '@/api/tenant';

export default {
	components: {
		BuildingFrom: buildingFrom,
	},
	data() {
		return {
			data: {
				name: '',
				groundFloors: 1, // 최대 20층A
				basementFloors: 0, // 최대 5층
				zip: null,
				address: null,
				addressDetail: null,
				imageId: null,
				imageFile: null,
				btnBool: true,
				rooftopYn: 'N',
				bool: false,
				longitude: null,
				latitude: null,
			},
		};
	},
	mounted() {
		hideMenu();
	},
	methods: {
		async onSave() {
			try {
				showSpinner();
				let imageId = null;
				if (this.data.imageFile) {
					const resFile = await file.upload({ file: this.data.imageFile });
					imageId = resFile.data[0].id;
				}

				const data = this.data;
				let req = {
					zip: data.zip,
					address: data.address,
					addressDetail: data.addressDetail,
					name: data.name,
					basementFloors: data.basementFloors,
					groundFloors: data.groundFloors,
					imageId,
					rooftopYn: data.rooftopYn,
				};

				let res = await getCoordinate(this.data.address);
				if (res) {
					req.longitude = res.longitude;
					req.latitude = res.latitude;
				}

				let bld = await building.create(req);
				await tenant.create({ buildingId: bld.id, name: '공통', managerName: ' ', contactNo: '0000000000' });
				this.$router.push({ name: 'buildingList' });
			} catch (ex) {
				this.$alert(`건물등록 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>

<style>
.buildWrap .buildArea .build,
.buildWrap .buildArea .build ul {
	background: none;
}
</style>
